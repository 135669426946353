import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { baseUrl, endPoints } from "../../data/endpoints";

const API = `${baseUrl}${endPoints.getEnquiryData}`;

const Enquiries = () => {
  const [enquiryMainData, setEnquiryMainData] = useState([]);

  /* start getting data */

  const getEnquiryData = (url) => {
    axios
      .get(url)
      .then((res) => {
        let enquiryData = res.data.data;
        if (enquiryData) {
          setEnquiryMainData(enquiryData);
        }
      })
      .catch((error) => console.log(error.message));
  };

  useEffect(() => {
    getEnquiryData(API);
  }, []);

  /* end getting data */

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead sx={{ bgcolor: "#102800" }}>
            <TableRow>
              <TableCell sx={{ color: "#ffffff" }}>Id</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Full Name</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Email</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Mobile No.</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Company Name</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Message</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {enquiryMainData.length ? (
              enquiryMainData.map((val, key) => (
                <TableRow key={key}>
                  <TableCell scope="row">{val.fldi_id}</TableCell>
                  <TableCell scope="row">{val.fldv_name}</TableCell>
                  <TableCell>{val.fldv_email}</TableCell>
                  <TableCell>{val.fldv_mobile}</TableCell>
                  <TableCell>{val.fldv_company}</TableCell>
                  <TableCell>{val.fldt_messages}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5}>No Data Found</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Enquiries;
