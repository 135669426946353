import React, { useEffect, useState } from "react";
import axios from "axios";
import SectionHeading from "./SectionHeading";
import { FaStar } from "@react-icons/all-files/fa/FaStar";
import { FaStarHalfAlt } from "@react-icons/all-files/fa/FaStarHalfAlt";
// import QuoteIcon from "../assets/quote-icon.png";
import CustomerQuoteWrap from "./CustomerQuoteWrap";
import { motion } from "framer-motion";
import { containerBlock, children } from "./animations/AboutAnimations.js";
import "../index.scss";
import { baseUrl, endPoints } from "../data/endpoints";

const CustomerReviews = ({ navbar }) => {
  const API = `${baseUrl}${endPoints.getReviewsData}`;

  const [reviewsData, setReviewsData] = useState([]);

  /* start getting data */

  const getReviewData = (url) => {
    axios
      .get(url)
      .then((res) => {
        let reviewData = res.data.data;
        if (reviewData) {
          setReviewsData(reviewData);
        }
      })
      .catch((error) => console.log(error.message));
  };

  useEffect(() => {
    getReviewData(API);
  }, [API]);

  /* end getting data */

  return (
    <section className="section-primary reviews-section" navbar={navbar}>
      <div className="container h-100">
        <div className="row g-0 h-100">
          <div className="col-12 text-center">
            <SectionHeading
              textColor="textColorPrimary"
              sectiontitle="customer"
              sectiontitle2="reviews"
            />

            <div className="reviews-section-wrap d-flex justify-content-center align-items-center">
              <div className="star-section">
                <FaStar className="icon" />
                <FaStar className="icon" />
                <FaStar className="icon" />
                <FaStar className="icon" />
                <FaStarHalfAlt className="icon" />
              </div>
              <div className="text-section">
                <p>4.5 out of 5</p>
              </div>
            </div>
          </div>

          <div className="col-12">
            <motion.div
              className="row g-0 g-md-5 align-items-center"
              variants={containerBlock}
              initial="initial"
              whileInView="animate"
              viewport={{ once: false }}
            >
              {reviewsData &&
                reviewsData.map((quoteData) => (
                  <motion.div
                    className="col-12 col-md-6"
                    key={quoteData.fldi_review_id}
                    variants={children}
                  >
                    <CustomerQuoteWrap quoteText={quoteData.fldv_comment} />
                  </motion.div>
                ))}
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomerReviews;
