import React, { useState } from "react";
import "../index.scss";
import axios from "axios";
import SectionHeading from "./SectionHeading";
// import { motion } from "framer-motion";
import animationData from "../assets/annur_banner_video.json";
import Lottie from "lottie-react";
import { baseUrl, endPoints } from "../data/endpoints";
const Contact = () => {
  const POSTAPI = `${baseUrl}${endPoints.postEnquiryData}`;

  const contactDataObj = {
    name: "",
    mobile: "",
    company: "",
    email: "",
    message: "",
  };

  const [successMessage, setSuccessMessage] = useState(
    "*All fields are mandatory!"
  );
  const [isError, setIsError] = useState(false);
  let [contactData, setContactData] = useState(contactDataObj);

  const handleContactContentChange = (e) => {
    setContactData({ ...contactData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    var emailValidExp = /[^\s@]+@[^\s@]+\.[^\s@]+/;
    var mobileValidExp = "[0-9]{10}";

    const sendEnquiryCall = () => {
      let configObj = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios
        .post(POSTAPI, contactData, configObj)
        .then((response) => {
          setSuccessMessage(response.data.message);
          setIsError(false);
          setContactData(contactDataObj);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    if (contactData) {
      if (
        contactData["name"] !== "" &&
        contactData["mobile"] !== "" &&
        contactData["company"] !== "" &&
        contactData["email"] !== "" &&
        contactData["message"] !== ""
      ) {
        console.log("inner", contactData["email"]);
        if (emailValidExp.test(contactData["email"])) {
          if (contactData["mobile"].match(mobileValidExp)) {
            sendEnquiryCall();
          } else {
            setSuccessMessage("Enter only numbers in mobile!");
            setIsError(true);
            return false;
          }
        } else {
          setSuccessMessage("Enter valid email id!");
          setIsError(true);
          return false;
        }
      } else {
        setSuccessMessage("Please fill in all details");
        setIsError(true);
      }
    }
  };

  return (
    <section className="contact-section">
      <div className="container h-100">
        <div className="row align-items-center">
          <div className="col-12 text-center mb-4">
            <SectionHeading
              textColor="textColorSecondary"
              sectiontitle="get"
              sectiontitle2="in"
              sectiontitle3="touch"
            />
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-12 col-md-6 order-last order-md-first">
            <p className={`enquiryErrorMessage ${isError && "errorMessage"}`}>
              {successMessage}
            </p>
            <form className="row g-3">
              <div className="col-md-6">
                <input
                  name="name"
                  type="text"
                  className="form-control"
                  placeholder="Full name"
                  onChange={handleContactContentChange}
                  value={contactData.name}
                  maxLength="80"
                />
              </div>
              <div className="col-md-6">
                <input
                  name="email"
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  onChange={handleContactContentChange}
                  value={contactData.email}
                  maxLength="150"
                />
              </div>
              <div className="col-md-6">
                <input
                  name="company"
                  type="text"
                  className="form-control"
                  placeholder="Company Name"
                  onChange={handleContactContentChange}
                  value={contactData.company}
                  maxLength="100"
                />
              </div>
              <div className="col-md-6">
                <input
                  name="mobile"
                  type="text"
                  className="form-control"
                  placeholder="Mobile Number"
                  onChange={handleContactContentChange}
                  value={contactData.mobile}
                  maxLength="10"
                />
              </div>
              <div className="col-12 mb-4">
                <textarea
                  name="message"
                  className="form-control"
                  rows="6"
                  placeholder="Message"
                  onChange={handleContactContentChange}
                  maxLength="300"
                  value={contactData.message}
                ></textarea>
              </div>
              <div className="col-12">
                <div className="button-wrap noset">
                  <span className="dot"></span>
                  <span className="text">Submit</span>
                  <input
                    className=""
                    type="button"
                    value="Submit"
                    onClick={handleSubmit}
                  />
                  <span className="wpcf7-spinner"></span>
                </div>
              </div>
            </form>
          </div>
          <div className="col-12 col-md-6 mb-4 mb-mb-0 order-first order-md-last">
            <Lottie
              animationData={animationData}
              loop={true}
              autoplay={true}
              speed={3.6}
              style={{ height: "100%" }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
