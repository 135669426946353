import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import PageTransition from "../components/animations/PageTransition";
import PageNotFound from "./PageNotFound";

const BlankPageMain = () => {
  return (
    <>
      <PageTransition />
      <Header />
      <PageNotFound />
      <Footer id="foot-contact" />
    </>
  );
};

export default BlankPageMain;
