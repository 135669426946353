import React, { useEffect } from "react";
import Header from "../components/Header";
import Contact from "../components/Contact";
import PageTransition from "../components/animations/PageTransition";
import SEO from "../components/SEO";

const ContactPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <>
      <SEO title="An-nur Contact Us" description="Get in touch with An-nur" />
      <PageTransition />
      <Header />
      <Contact />
    </>
  );
};

export default ContactPage;
