import React from "react";
import KajalRange from "../components/KajalRange";
import Header from "../components/Header";
import PageTransition from "../components/animations/PageTransition";
import SEO from "../components/SEO";

const KajalPage = () => {
  // useEffect(() => {
  //   window.scrollTo({ top: 0 });
  // }, []);
  return (
    <>
      <SEO
        title="An-nur Kajal Collection"
        description="An-nur Kajal Collection."
      />
      <PageTransition />
      <Header isProductDetails={false} />
      <KajalRange />
    </>
  );
};

export default KajalPage;
