// const tempCorsUrl = "https://cors-anywhere.herokuapp.com/";
// const tempCorsUrl = "http://localhost:8080/";
export let baseUrl = "";
// baseUrl = `${tempCorsUrl}https://annurayurvedic.com/backend/`;
baseUrl = "https://annurayurvedic.com/backend/";

// end points

export const endPoints = {
  getAboutData: "api/page/list",
  postAboutData: "api/page/update/1",
  getReviewsData: "api/review/list",
  postReviewsData: "api/review/add",
  getProductsData: "api/product/list",
  getProductDetail: "api/product/detail/",
  getProductDetailBySlug: "api/product/slug/",
  postEnquiryData: "api/enquiry/add",
  getEnquiryData: "api/enquiry/list",
};
