import React from "react";
// import { FooterBtn } from "./Button";
import SectionHeading from "./SectionHeading";
import ContactWrapper from "./ContactWrapper";
import { ContactWrapCont } from "./ContactContent";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { containerBlock, children } from "./animations/AboutAnimations";

const Footer = ({ id, navbar }) => {
  return (
    <div className="footer-section section-primary" id={id} navbar={navbar}>
      <div className="container h-100">
        <div className="row g-0 justify-content-center">
          <div className="col-12 col-md-7 pb-md-0 pb-5">
            <SectionHeading
              textColor="textColorPrimary"
              // sectiontitle="get in touch"
              sectiontitle="get"
              sectiontitle2="in"
              sectiontitle3="touch"
            />

            <Link to="/contact" className="react-link">
              <div className="button-wrap noset mt-4">
                <span className="dot"></span>
                <span className="text">SAY HELLO</span>
                <span className="wpcf7-spinner"></span>
              </div>
            </Link>
          </div>
          <motion.div
            className="col-12 col-md-5 border-left"
            variants={containerBlock}
            initial="initial"
            whileInView="animate"
            viewport={{ once: false }}
          >
            <motion.span
              variants={children}
              style={{ display: "block", paddingBottom: "30px" }}
            >
              <ContactWrapper>
                <ContactWrapCont
                  title="Email"
                  link="mailto:info@annurayurvedic.com"
                  textlink="info@annurayurvedic.com"
                />
              </ContactWrapper>
            </motion.span>
            <motion.span
              variants={children}
              style={{ display: "block", paddingBottom: "30px" }}
            >
              <ContactWrapper>
                <ContactWrapCont
                  title="Phone"
                  link="tel:+919022777786"
                  textlink="+91 9022777786"
                />
              </ContactWrapper>
            </motion.span>
            <motion.span
              variants={children}
              style={{ display: "block", paddingBottom: "30px" }}
            >
              <ContactWrapper>
                <span className="contact-title">Location</span>
                <span className="address-text">
                  Shripal Industrial Estate. 3/B First Floor,
                </span>
                <span className="address-text">
                  Gala No 108, Behind Varun Industries,
                </span>
                <span className="address-text">
                  Near Gyandeep School, Golani Naka,
                </span>
                <span className="address-text">
                  Valiv, Vasai (East) 401 208.
                </span>
              </ContactWrapper>
            </motion.span>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
