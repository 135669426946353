import "../index.scss";

export const CertificateIcon = ({ certificateIcon, altName }) => {
  return (
    <div className="certificate-icon">
      <img src={certificateIcon} alt={altName} className="img-fluid" />
    </div>
  );
};

export const CertificateIconText = ({ certificateIcon, altName, title }) => {
  return (
    <div className="certificate-icon-text">
      <img src={certificateIcon} alt={altName} className="img-fluid" />
      <p className="certificate-text">{title}</p>
    </div>
  );
};
