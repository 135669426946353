import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl, endPoints } from "../../data/endpoints";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const Products = ({ setPageTitle }) => {
  // const POSTAPI = `${baseUrl}${endPoints.postReviewsData}`;
  const API = `${baseUrl}${endPoints.getProductsData}`;

  let [productsData, setProductsData] = useState([]);

  /* start getting data */

  const getProductsData = (url) => {
    axios
      .get(url)
      .then((res) => {
        let productMainData = res.data.data;
        if (productMainData.length) {
          setProductsData(productMainData);
        }
      })
      .catch((error) => console.log(error.message));
  };

  useEffect(() => {
    getProductsData(API);
  }, [API]);

  const navigate = useNavigate();

  const handleEdit = (prodId, pageName) => {
    setPageTitle(pageName);
    navigate(`/admin/${pageName}/${prodId}`);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead sx={{ bgcolor: "#102800" }}>
            <TableRow>
              <TableCell sx={{ color: "#ffffff" }}>Id</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Title</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Category</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Image</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productsData.length ? (
              productsData.map((val, key) => (
                <TableRow key={key}>
                  <TableCell scope="row">{val.fldi_product_id}</TableCell>
                  <TableCell scope="row" sx={{ textTransform: "uppercase" }}>
                    {val.fldv_title}
                  </TableCell>
                  <TableCell>
                    {val.fldi_cat_id === "1" ? "Surma" : "Kajal"}
                  </TableCell>
                  <TableCell>
                    <img
                      src={val.fldv_main_image}
                      alt={val.fldv_title}
                      style={{ maxHeight: "100px" }}
                    />
                  </TableCell>
                  <TableCell>
                    {
                      <Button
                        variant="outlined"
                        startIcon={<EditIcon />}
                        onClick={() => {
                          handleEdit(val.fldi_product_id, "edit-product");
                        }}
                      >
                        Edit
                      </Button>
                    }
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5}>No Data Found</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Products;
