import React from "react";
import "../index.scss";
import { motion } from "framer-motion";
import { sentence, letter } from "./animations/TextAnimations.js";

const SectionHeading = ({
  sectiontitle,
  sectiontitle2,
  sectiontitle3,
  textColor,
}) => {
  return (
    <>
      <motion.h3
        className={`section-header-title ${textColor}`}
        variants={sentence}
        initial="initial"
        whileInView="animate"
        viewport={{ once: false }}
      >
        {sectiontitle.split("").map((char, key) => {
          return (
            <motion.span key={`${char}+${key}`} variants={letter}>
              {char}
            </motion.span>
          );
        })}
        {sectiontitle2 && " "}
        {sectiontitle2 &&
          sectiontitle2.split("").map((char, key) => {
            return (
              <motion.span key={`${char}+${key}`} variants={letter}>
                {char}
              </motion.span>
            );
          })}
        {sectiontitle3 && " "}
        {sectiontitle3 &&
          sectiontitle3.split("").map((char, key) => {
            return (
              <motion.span key={`${char}+${key}`} variants={letter}>
                {char}
              </motion.span>
            );
          })}
      </motion.h3>
    </>
  );
};

export default SectionHeading;
