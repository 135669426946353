export const sentence = {
  initial: { opacity: 1 },
  animate: {
    opacity: 1,
    transition: {
      delay: 3,
      staggerChildren: 0.08,
      delayChildren: 0.2,
    },
  },
};

export const letter = {
  initial: { scaleY: 0, y: 50, x: -30, opacity: 0.5 },
  animate: {
    scaleY: 1,
    y: 0,
    x: 0,
    opacity: 1,
  },
};
