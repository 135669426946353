import React, { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import BlankPage from "./admin/pages/BlankPage";
import Dashboard from "./admin/pages/Dashboard";
import Login from "./admin/pages/Login";

import HomePage from "./pages/HomePage";
import KajalPage from "./pages/KajalPage";
import SurmaPage from "./pages/SurmaPage";
import ContactPage from "./pages/ContactPage";
import ProductDetails from "./pages/ProductDetails";

import BlankPageMain from "./components/BlankPageMain";

const adminTheme = createTheme({
  palette: {
    primary: {
      main: "#102800",
    },
    secondary: {
      main: "#becfa5",
    },
  },
});

const AnimatedRoutes = () => {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("dashboard");
  const [isLogin, setIsLogin] = useState(false);

  // const [isVisible, setIsVisible] = useState(true);

  return (
    <>
      <Routes key={location.pathname} location={location}>
        <Route path="/">
          <Route index element={<HomePage />} />
          <Route path="/kajal-collection" element={<KajalPage />} />
          <Route path="/surma-collection" element={<SurmaPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route
            path="/:category/:productSlug"
            element={<ProductDetails />}
          />

          <Route
            path="/admin/login"
            element={
              <ThemeProvider theme={adminTheme}>
                <Login setIsLogin={setIsLogin} setPageTitle={setPageTitle} />
              </ThemeProvider>
            }
          />
          <Route path="/*" exact={true} element={<BlankPageMain />} />

          {pageTitle === "dashboard" ? (
            <Route
              path="admin"
              element={
                <ThemeProvider theme={adminTheme}>
                  <Dashboard
                    pageTitle="dashboard"
                    setPageTitle={setPageTitle}
                    isLogin={isLogin}
                    setIsLogin={setIsLogin}
                  />
                </ThemeProvider>
              }
            />
          ) : (
            <Route
              path={`/admin/${pageTitle}`}
              element={
                <ThemeProvider theme={adminTheme}>
                  <Dashboard
                    pageTitle={`${pageTitle}`}
                    setPageTitle={setPageTitle}
                    isLogin={isLogin}
                    setIsLogin={setIsLogin}
                  />
                </ThemeProvider>
              }
            />
          )}

          <Route
            path={`/admin/edit-product/:productId`}
            element={
              <ThemeProvider theme={adminTheme}>
                <Dashboard
                  pageTitle={`${pageTitle}`}
                  setPageTitle={setPageTitle}
                  isLogin={isLogin}
                  setIsLogin={setIsLogin}
                />
              </ThemeProvider>
            }
          />

          <Route
            path="/admin/*"
            exact={true}
            element={
              <ThemeProvider theme={adminTheme}>
                <BlankPage setPageTitle={setPageTitle} />
              </ThemeProvider>
            }
          />
        </Route>
      </Routes>
    </>
  );
};

export default AnimatedRoutes;
