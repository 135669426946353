import React from "react";
import { motion } from "framer-motion";
import { mainImageBlock, imgChild } from "./animations/AboutAnimations";
import "../index.scss";
import { productIcons } from "./imageLinksObj";
import SectionHeading from "./SectionHeading";
import { CertificateIcon } from "./CertificateWrap";

const Certificate = ({ id, navbar }) => {
  return (
    <section
      className="section-2 section-secondary conclusion certificate-section"
      id={id}
      navbar={navbar}
    >
      <div className="container">
        <div className="row g-0 justify-content-center">
          <div className="col-12 text-center mb-3">
            <SectionHeading
              textColor="textColorSecondary"
              sectiontitle="certifications"
            />
          </div>
          <div className="col-12">
            <div className="row align-items-center">
              <div className="col-12">
                <motion.div
                  className="certificate-section-left"
                  variants={mainImageBlock}
                  initial="initial"
                  whileInView="animate"
                  viewport={{ once: false }}
                >
                  <motion.span variants={imgChild}>
                    <CertificateIcon
                      certificateIcon={productIcons["IconCertIso"]}
                      altName="icon 1"
                    />
                  </motion.span>
                  <motion.span variants={imgChild}>
                    <CertificateIcon
                      certificateIcon={productIcons["iconCertGmp"]}
                      altName="icon 2"
                    />
                  </motion.span>
                  <motion.span variants={imgChild}>
                    <CertificateIcon
                      certificateIcon={productIcons["iconCertFda"]}
                      altName="icon 3"
                    />
                  </motion.span>
                  <motion.span variants={imgChild}>
                    <CertificateIcon
                      certificateIcon={productIcons["iconCertHaccp"]}
                      altName="icon 4"
                    />
                  </motion.span>
                </motion.div>
              </div>
              {/* <div className="col-12 col-md-6 col-lg-5 border-left">
                <motion.div
                  className="certificate-section-right"
                  variants={mainImageBlock}
                  initial="initial"
                  whileInView="animate"
                  viewport={{ once: false }}
                >
                  <motion.span variants={imgChild}>
                    <CertificateIconText
                      certificateIcon={productIcons["iconSmudgeFree"]}
                      altName="icon 5"
                      title="Smudge Free"
                    />
                  </motion.span>
                  <motion.span variants={imgChild}>
                    <CertificateIconText
                      certificateIcon={productIcons["iconLeadFree"]}
                      altName="icon 6"
                      title="Lead-Free"
                    />
                  </motion.span>
                  <motion.span variants={imgChild}>
                    <CertificateIconText
                      certificateIcon={productIcons["iconHerbal"]}
                      altName="icon 7"
                      title="100% Herbal"
                    />
                  </motion.span>
                </motion.div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Certificate;
