import React, { useEffect, useState } from "react";
import axios from "axios";
import "../index.scss";
import SectionHeading from "./SectionHeading";
import Img1 from "../assets/about-img-1.png";
import Img2 from "../assets/about-img-2.png";
import { motion } from "framer-motion";
import {
  containerBlock,
  children,
  mainImageBlock,
  imgChild,
} from "../components/animations/AboutAnimations";
import { baseUrl, endPoints } from "../data/endpoints";

const About = ({ navbar, id }) => {
  const API = `${baseUrl}${endPoints.getAboutData}`;

  // const [aboutParaOne, setAboutParaOne] = useState(
  //   "An-Nur is a Unani product manufacturing company that is ISO 9001:2015, HACCP certified. All An-Nur products are FDA and GMP certified, with a reputation of being of the utmost high quality following the most stringent quality parameters for eye care products. Our products involve the use of genuine and effective ancient herbs. This marked the beginning of An-Nur while leaving its founders with a vision to provide pure herbal remedies for generations to come."
  // );
  // const [aboutParaTwo, setAboutParaTwo] = useState(
  //   "An-Nur Surmas and Kajals are made in accordance with the authentic formulae established by stalwarts of Unani medicine. The efficacy of ancient herbs and formulae is enhanced with modern research and methods of production. With constant innovation and determination, An-Nur has produced various types of Surmas and Kajals, each dedicated to cater various eye care concerns and provide the comfort of healthy vision."
  // );
  const [aboutParaOne, setAboutParaOne] = useState("");
  const [aboutParaTwo, setAboutParaTwo] = useState("");

  /* start getting data */

  const getAboutData = (url) => {
    axios
      .get(url)
      .then((res) => {
        let aboutData = res.data.data[0];
        if (aboutData) {
          setAboutParaOne(aboutData.fldv_desc_one);
          setAboutParaTwo(aboutData.fldv_desc_two);
        }
      })
      .catch((error) => console.log(error.message));
  };

  useEffect(() => {
    getAboutData(API);
  }, [API]);

  /* end getting data */

  return (
    <section className="about-section" navbar={navbar} id={id}>
      <div className="container h-100">
        <div className="row g-0 align-items-center h-100">
          <div className="col-12 col-lg-7">
            <motion.div
              variants={containerBlock}
              initial="initial"
              whileInView="animate"
              viewport={{ once: false }}
            >
              <SectionHeading
                textColor="textColorPrimary"
                sectiontitle="about"
                sectiontitle2="us"
              />
              <motion.p className="pt-2 d-block" variants={children}>
                {aboutParaOne}
              </motion.p>
              <motion.p className="d-block" variants={children}>
                {aboutParaTwo}
              </motion.p>
            </motion.div>
          </div>
          <div className="col-12 col-lg-5 text-center pt-5 pt-lg-0">
            <motion.div
              variants={mainImageBlock}
              initial="initial"
              whileInView="animate"
              viewport={{ once: false }}
              className="about-images-wrapper"
            >
              <motion.img
                src={Img1}
                className="about-img-1"
                alt="about-img-1"
                variants={imgChild}
              />
              <motion.img
                src={Img2}
                className="about-img-2"
                alt="about-img-2"
                variants={imgChild}
              />
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
