import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../index.scss";
import { Link } from "react-router-dom";

const ProductListSlider = ({ category, productList }) => {
  var windowWidthMatch = window.matchMedia("(min-width: 768px)");

  const slider = useRef();

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    draggable: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 3000,
    focusOnSelect: true,
    pauseOnHover: false,
    centerMode: true,
    centerPadding: "20px",

    afterChange: () => {},

    beforeChange: (prev, next) => {
      if (windowWidthMatch.matches) {
        let curSlideElement = slider.current.innerSlider.list.querySelector(
          `[data-index="${next}"]`
        );

        let prevSlideElement = curSlideElement.previousSibling;
        let prevToPrevSlideElement = prevSlideElement.previousSibling;
        let nextSlideElement = curSlideElement.nextSibling;
        let nextToNextSlideElement = nextSlideElement.nextSibling;

        if (next === 0) {
        }

        setTimeout(() => {
          prevToPrevSlideElement.querySelector(
            ".product-img"
          ).style.cssText = `transform: rotate(-28deg) translateY(15px);`;
          prevToPrevSlideElement.querySelector(
            ".product-range-title"
          ).style.cssText = `background-color: tranparent;color: #becfa5;`;

          prevSlideElement.querySelector(
            ".product-img"
          ).style.cssText = `transform: rotate(-14deg) translateY(15px);`;
          prevSlideElement.querySelector(
            ".product-range-title"
          ).style.cssText = `background-color: tranparent;color: #becfa5;`;

          nextSlideElement.querySelector(
            ".product-img"
          ).style.cssText = `transform: rotate(14deg) translateY(15px);`;
          nextSlideElement.querySelector(
            ".product-range-title"
          ).style.cssText = `background-color: tranparent;color: #becfa5;`;

          nextToNextSlideElement.querySelector(
            ".product-img"
          ).style.cssText = `transform: rotate(28deg) translateY(20px);`;
          nextToNextSlideElement.querySelector(
            ".product-range-title"
          ).style.cssText = `background-color: tranparent;color: #becfa5;`;

          curSlideElement.querySelector(
            ".product-range-title"
          ).style.cssText = `background-color: #becfa5;color: #122d06;`;

          curSlideElement.querySelector(
            ".product-img"
          ).style.cssText = `transform: rotate(0deg) translateY(0px);`;
        });
      }
    },

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    if (windowWidthMatch.matches) {
      if (productList) {
        setTimeout(() => {
          let curSlide = document.querySelector(
            ".productSliderWrapper .slick-current"
          );
          if (curSlide) {
            let prevElem = curSlide.previousSibling;
            let nextElem = curSlide.nextSibling;
            let nextToNextElem = nextElem.nextSibling;

            prevElem.querySelector(".product-img").style.cssText =
              "transform: rotate(-14deg) translateY(15px);";
            prevElem.querySelector(
              ".product-range-title"
            ).style.cssText = `background-color: tranparent;color: #becfa5;`;

            nextElem.querySelector(".product-img").style.cssText =
              "transform: rotate(14deg) translateY(15px);";
            nextElem.querySelector(
              ".product-range-title"
            ).style.cssText = `background-color: tranparent;color: #becfa5;`;

            nextToNextElem.querySelector(
              ".product-img"
            ).style.cssText = `transform: rotate(28deg) translateY(20px);`;
            nextToNextElem.querySelector(
              ".product-range-title"
            ).style.cssText = `background-color: tranparent;color: #becfa5;`;

            curSlide.querySelector(
              ".product-range-title"
            ).style.cssText = `background-color: #becfa5;color: #122d06;`;

            curSlide.querySelector(
              ".product-img"
            ).style.cssText = `transform: rotate(0deg) translateY(0px);`;
          }
        }, 0);
      }
    }
  }, [productList, windowWidthMatch.matches]);

  return (
    <>
      <Slider {...settings} ref={slider} className="productSliderWrapper">
        {productList &&
          productList.map((item) => (
            <div key={item.fldi_product_id}>
              <div className="text-center">
                <Link
                  to={`/${category}/${item.fldv_slug}`}
                  className="react-link"
                >
                  <img
                    // src={`${BASE_URL}${item.main_image}`}
                    src={item.fldv_main_image}
                    alt={item.fldv_title}
                    className="img-fluid product-img"
                  />
                  <h4 className="product-range-title text-uppercase">
                    {item.fldv_title}
                  </h4>
                </Link>
              </div>
            </div>
          ))}
      </Slider>
    </>
  );
};

export default ProductListSlider;
