import React from "react";
import animationData from "../assets/annur_banner_video.json";
import Lottie from "lottie-react";
import HeroTextAnimation from "./HeroTextAnimation";
import "../index.scss";

const HeroSection = (props) => {
  return (
    <section className="hero-section" navbar={props.navbar}>
      <div className="container h-100">
        <div className="row g-0 align-items-center h-100">
          <div className="col-12 col-md-6 order-last order-md-first">
            <HeroTextAnimation />
          </div>
          <div className="col-12 col-md-6 order-first order-md-last">
            <Lottie
              animationData={animationData}
              loop={true}
              autoplay={true}
              speed={3.6}
              style={{ height: "100%" }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
