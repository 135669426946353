import React from "react";
import { BrowserRouter } from "react-router-dom";
// import { motion, AnimatePresence } from "framer-motion";
import "./App.scss";
import AnimatedRoutes from "./AnimatedRoutes";
import { HelmetProvider } from "react-helmet-async";

const App = () => {
  const helmetContext = {};
  return (
    <>
      <HelmetProvider context={helmetContext}>
        {/* <BrowserRouter basename={"/annur"}> */}
        <BrowserRouter>
          <AnimatedRoutes />
        </BrowserRouter>
      </HelmetProvider>
    </>
  );
};

export default App;
