import React from "react";
import Header from "../components/Header";
import SurmaRange from "../components/SurmaRange";
import PageTransition from "../components/animations/PageTransition";
import SEO from "../components/SEO";

const SurmaPage = () => {
  // useEffect(() => {
  //   window.scrollTo({ top: 0 });
  // }, []);
  return (
    <>
      <SEO
        title="An-nur Surma Collection"
        description="An-nur Surma Collection."
      />
      <PageTransition />
      <Header />
      <SurmaRange />
    </>
  );
};

export default SurmaPage;
