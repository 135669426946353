import React from "react";
import SurmaImage from "./../assets/surma-1.png";
import KajalImage from "./../assets/surma-2.png";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { sentence, letter } from "./animations/TextAnimations.js";
import {
  mainImageBlock,
  imgChild,
} from "../components/animations/AboutAnimations";
import "../index.scss";

const CollectionsSection = ({ navbar, id }) => {
  const line1 = "surma";
  const line2 = "kajal";
  const line3 = "collection";
  return (
    <section className="section-primary surma-section" id={id} navbar={navbar}>
      <div className="container h-100">
        <div className="row align-items-center h-100">
          <div className="col-12 col-md-6 text-center">
            <Link to="/surma-collection" className="react-link">
              <motion.div
                variants={mainImageBlock}
                initial="initial"
                whileInView="animate"
                viewport={{ once: false }}
                className="d-inline-block"
              >
                <motion.img
                  src={SurmaImage}
                  className="img-fluid"
                  alt="surma"
                  variants={imgChild}
                />
              </motion.div>
              {/* <div className="d-inline-block">
                <img src={SurmaImage} className="img-fluid" alt="surma" />
              </div> */}

              <motion.h3
                className="section-header-title pt-2 textColorSecondary"
                variants={sentence}
                initial="initial"
                whileInView="animate"
                viewport={{ once: false }}
              >
                {line1.split("").map((char, key) => {
                  return (
                    <motion.span key={`${char}+${key}`} variants={letter}>
                      {char}
                    </motion.span>
                  );
                })}
                <br />
                {line3.split("").map((char, key) => {
                  return (
                    <motion.span key={`${char}+${key}`} variants={letter}>
                      {char}
                    </motion.span>
                  );
                })}
              </motion.h3>

              {/* <h3 className="section-header-title pt-2 textColorSecondary">
                surma <br />
                collection
              </h3> */}
            </Link>
          </div>

          <div className="col-12 col-md-6 text-center pt-md-0 pt-5">
            <Link to="/kajal-collection" className="react-link">
              <motion.div
                variants={mainImageBlock}
                initial="initial"
                whileInView="animate"
                viewport={{ once: false }}
                className="d-inline-block"
              >
                <motion.img
                  src={KajalImage}
                  className="img-fluid"
                  alt="kajal"
                  variants={imgChild}
                />
              </motion.div>
              {/* <div className="d-inline-block">
                <img src={KajalImage} className="img-fluid" alt="surma" />
              </div> */}

              <motion.h3
                className="section-header-title pt-2 textColorSecondary"
                variants={sentence}
                initial="initial"
                whileInView="animate"
                viewport={{ once: false }}
              >
                {line2.split("").map((char, key) => {
                  return (
                    <motion.span key={`${char}+${key}`} variants={letter}>
                      {char}
                    </motion.span>
                  );
                })}
                <br />
                {line3.split("").map((char, key) => {
                  return (
                    <motion.span key={`${char}+${key}`} variants={letter}>
                      {char}
                    </motion.span>
                  );
                })}
              </motion.h3>
              {/* <h3 className="section-header-title pt-2 textColorSecondary">
                kajal <br />
                collection
              </h3> */}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CollectionsSection;
