export const containerBlock = {
  initial: { opacity: 1 },
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 0.5,
      delayChildren: 0.2,
    },
  },
};

export const children = {
  initial: { scaleY: 0, y: 50, x: -30, opacity: 0.3 },
  animate: {
    scaleY: 1,
    y: 0,
    x: 0,
    opacity: 1,
  },
};

export const mainImageBlock = {
  initial: { opacity: 1 },
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
      delayChildren: 0.3,
    },
  },
};

export const imgChild = {
  initial: { y: 200, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1,
      ease: [0.87, 0, 0.13, 1],
    },
  },
};
