import React from 'react'
import { useNavigate } from 'react-router-dom';

const BlankPage = ({ setPageTitle }) => {

    const navigate = useNavigate();

    const handlePageChange = () => {
        navigate("/admin")
        setPageTitle("dashboard");
    }

    return (
        <>
            <div>Invalid Redirection, Use the menu bar from dashboard.</div>
            <button onClick={() => { handlePageChange() }}>DashBoard</button>
        </>
    )
}

export default BlankPage