import React from "react";

const BlankPageMain = () => {
  return (
    <>
      <div className="pagenotfoundWrapper">
        <h1 className="text-center head-blank-page">404</h1>
        <h3 className="text-center head-blank-text">
          We're not sure how you got here...
        </h3>
        <h3 className="text-center head-blank-text">
          But you may be lost in our Surma or Kajal applied eyes!
        </h3>
      </div>
    </>
  );
};

export default BlankPageMain;
