import React from "react";
import HeroSection from "../components/HeroSection";
import About from "../components/About";
import CollectionsSection from "../components/CollectionsSection";
import CustomerReviews from "../components/CustomerReviews";
import Certificate from "../components/Certificate";
import Footer from "../components/Footer";
import Header from "../components/Header";
import PageTransition from "../components/animations/PageTransition";
import SEO from "../components/SEO";

const HomePage = ({ navbar }) => {
  return (
    <>
      <SEO
        title="An-Anur - Best Surma and Kajal Collections"
        description="An-Nur is a Unani product manufacturing company that is ISO 9001:2015, HACCP certified. All An-Nur products are FDA and GMP certified, with a reputation of being of the utmost high quality following the most stringent quality parameters for eye care products."
      />
      <PageTransition />
      <Header />
      <HeroSection />
      <About id="about-section" />
      <CollectionsSection id="collections" />
      <CustomerReviews />
      <Certificate id="certifications" />
      <Footer id="foot-contact" />
    </>
  );
};

export default HomePage;
