import React, { useEffect, useState } from "react";
import axios from "axios";
import parse from "html-react-parser";
import { useParams, useNavigate, Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../components/Header";
import PageTransition from "../components/animations/PageTransition";
import { baseUrl, endPoints } from "../data/endpoints";
import { productIcons } from "../components/imageLinksObj";
import SEO from "../components/SEO";
import DetailsNav from "../components/DetailsNav";

const ProductDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  let { category, productSlug } = params;

  const [productItemData, setProductItemData] = useState({});
  const [productItemImageData, setProductItemImageData] = useState([]);
  const [productItemIconData, setProductItemIconData] = useState([]);
  const [productItemSlug, setProductItemSlug] = useState("");
  const [productNextSlug, setProductNextSlug] = useState("");
  const [productPrevSlug, setProductPrevSlug] = useState("");

  const API = `${baseUrl}${endPoints.getProductDetailBySlug}${productSlug}`;

  /* start getting data */

  const getProductData = (url) => {
    if (!category || !productSlug) {
      navigate("/");
    } else {
      if (category && productSlug) {
        setProductItemSlug(productSlug);
        if (productItemSlug) {
          axios
            .get(`${url}/${productItemSlug}`)
            .then((res) => {
              let nextSlug = res.data.next_slug;
              let prevSlug = res.data.prev_slug;
              let productData = res.data.data;
              let imageData = productData.images;
              if (nextSlug) {
                setProductNextSlug(nextSlug);
              }
              if (prevSlug) {
                setProductPrevSlug(prevSlug);
              }
              if (productData) {
                setProductItemData(productData);
              }
              if (imageData) {
                setProductItemImageData(
                  imageData.filter((val) => {
                    return val.flg_img_type === "1";
                  })
                );
                setProductItemIconData(
                  imageData.filter((val) => {
                    return val.flg_img_type === "2";
                  })
                );
              }
            })
            .catch((error) => console.log(error.message));
        }
      }
    }
  };

  useEffect(() => {
    getProductData(API);
    // eslint-disable-next-line
  }, [API, productItemSlug]);

  /* end getting data */

  const product_icons = [
    { title: "Smudge Free", img: productIcons["iconSmudgeFree"] },
    { title: "100% Herbal", img: productIcons["iconHerbal"] },
    { title: "Lead-Free", img: productIcons["iconLeadFree"] },
  ];

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      <SEO
        title={
          category &&
          productItemData &&
          `${category.replace("-", " ").toUpperCase()} | ${
            productItemData.fldv_title
          }`
        }
        description={
          category &&
          productItemData &&
          `${category.replace("-", " ").toUpperCase()} | ${
            productItemData.fldv_title
          } | ${productItemData.fldt_short_desc}`
        }
      />
      <PageTransition />
      <Header isProductDetails={true} />

      {Object.keys(productItemData).length && (
        <section className="product-details">
          <div className="container mb-4">
            <p className="text-end">
              <Link
                to={`/${category}/`}
                className="react-link go-back text-end"
              >
                {category === "kajal-collection"
                  ? "Kajal Collection"
                  : "Surma Collection"}
              </Link>
            </p>
          </div>
          <div className="container h-100">
            <DetailsNav
              productNextSlug={productNextSlug && productNextSlug}
              productPrevSlug={productPrevSlug && productPrevSlug}
              category={category && category}
            />
            <div className="row product-wrapper align-items-center h-100">
              <div className="col-12 text-left">
                <h3 className="section-title">{productItemData.fldv_title}</h3>
                <p className="description-text pb-5">
                  {productItemData.fldt_short_desc}
                </p>
              </div>
              <div className="col-12 col-md-4 text-center">
                <Slider {...settings}>
                  <div>
                    <img
                      src={productItemData.fldv_main_image}
                      className="img-fluid product-img"
                      alt={productItemData.fldv_main_image_title}
                      srcSet=""
                    />
                    {/* <p className="product-details-text pb-3 pb-md-0">
                          {productDataImg.productTitle}
                        </p> */}
                  </div>

                  {productItemImageData &&
                    productItemImageData.map((productDataImg) => {
                      return (
                        <div key={productDataImg.fldi_id}>
                          <img
                            src={productDataImg.fldv_image}
                            className="img-fluid product-img"
                            alt={productDataImg.fldv_title}
                            srcSet=""
                          />
                          {/* <p className="product-details-text pb-3 pb-md-0">
                          {productDataImg.fldv_title}
                        </p> */}
                        </div>
                      );
                    })}
                </Slider>
                <p className="product-details-text pb-3 pb-md-0">
                  {productItemData.fldv_availability}
                </p>
              </div>
              <div className="col-12 col-md-8 pt-5 pt-md-0">
                <h4 className="product-title">
                  {productItemData.fldv_main_desc_title}
                </h4>
                <ul className="list_point">
                  {parse(productItemData.fldv_main_desc)}
                </ul>
                {/* <div className="product-certificate-section">
                  {productItemData.product_details.product_certificate.map(
                    (certificate, i) => {
                      return (
                        <div className="certificate-icon" key={i}>
                          <img
                            src={certificate.img}
                            alt="icon 1"
                            className="img-fluid"
                          />
                        </div>
                      );
                    }
                  )}
                </div> */}
                <div className="product-certificate-section pt-2">
                  {productItemIconData &&
                    productItemIconData.map((icon, i) => {
                      return (
                        <div className="certificate-icon-text" key={i}>
                          <img
                            src={icon.fldv_image}
                            alt="icon 3"
                            className="img-fluid"
                          />
                          <p className="certificate-text">{icon.fldv_title}</p>
                        </div>
                      );
                    })}
                  {product_icons &&
                    product_icons.map((icon, i) => {
                      return (
                        <div className="certificate-icon-text" key={i}>
                          <img
                            src={`${icon.img}`}
                            alt="icon 3"
                            className="img-fluid"
                          />
                          <p className="certificate-text">{icon.title}</p>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ProductDetails;
