import React from "react";
import { motion } from "framer-motion";
import { sentence, letter } from "./animations/TextAnimations.js";

const HeroTextAnimation = () => {
  const line1 = "enchanting";
  const line2 = "eyes,";
  const line3 = "naturally";

  return (
    <motion.h3
      className="hero-text"
      variants={sentence}
      initial="initial"
      whileInView="animate"
      viewport={{ once: false }}
    >
      {line1.split("").map((char, key) => {
        return (
          <motion.span key={`${char}+${key}`} variants={letter}>
            {char}
          </motion.span>
        );
      })}
      <br />
      {line2.split("").map((char, key) => {
        return (
          <motion.span key={`${char}+${key}`} variants={letter}>
            {char}
          </motion.span>
        );
      })}
      &nbsp;
      {line3.split("").map((char, key) => {
        return (
          <motion.span key={`${char}+${key}`} variants={letter}>
            {char}
          </motion.span>
        );
      })}
    </motion.h3>
  );
};

export default HeroTextAnimation;
