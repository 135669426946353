import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, TextField, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Container } from "@mui/material";
import axios from "axios";
import { baseUrl, endPoints } from "../../data/endpoints";
import { green } from "@mui/material/colors";
import { red } from "@mui/material/colors";

const Reviews = () => {
  const POSTAPI = `${baseUrl}${endPoints.postReviewsData}`;
  const API = `${baseUrl}${endPoints.getReviewsData}`;

  const [isEdit, setIsEdit] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");

  let reviewDataObj = {
    // id:"",
    comment: "",
    rating: "",
  };

  let [reviewData, setReviewData] = useState(reviewDataObj);

  let [reviewsData, setReviewsData] = useState([]);

  /* start getting data */

  const getReviewsData = (url) => {
    axios
      .get(url)
      .then((res) => {
        let reviewsMainData = res.data.data;
        if (reviewsMainData.length) {
          setReviewsData(reviewsMainData);
        }
      })
      .catch((error) => console.log(error.message));
  };

  useEffect(() => {
    getReviewsData(API);
  }, [API]);

  /* end getting data */

  // const handleEdit = (id) => {
  //   setSelectedId(id);
  //   setIsEdit(true);
  // };

  // const handleReviewContentChange = (e) => {
  //   setReviewData({
  //     ...reviewData,
  //     [e.target.name]: e.target.value,
  //     fldi_review_id: selectedId,
  //   });
  // };

  /* main func for API call */

  // const handleContentSave = (id) => {
  //   setIsEdit(false);
  //   setSelectedId(0);
  //   console.log("reviewData to update", reviewData, "id", id);
  // };

  /* ---- */

  let reviewNewDataObj = {
    comment: "",
    rating: "",
  };

  const [isNewActive, setIsNewActive] = useState(false);
  let [reviewNewData, setReviewNewData] = useState(reviewNewDataObj);
  const [isError, setIsError] = useState(false);

  const handleAddNew = () => {
    setIsNewActive(true);
    setReviewNewData(reviewNewDataObj);
    setSuccessMessage("");
  };
  const handleAddNewClose = () => {
    setIsNewActive(false);
  };

  const handleNewReviewContentChange = (e) => {
    setReviewNewData({
      ...reviewNewData,
      [e.target.name]: e.target.value,
    });
  };

  /* main func for API call */

  const handleAddContentSave = () => {
    // setSelectedId(0);
    axios
      .post(POSTAPI, reviewNewData)
      .then((response) => {
        if (response.data.status === "success") {
          setSuccessMessage(response.data.message);
          getReviewsData(API);
          setReviewNewData(reviewNewDataObj);
          setIsNewActive(false);
          setIsError(false);
        } else {
          setSuccessMessage(response.data.message);
          getReviewsData(API);
          setIsNewActive(true);
          setIsError(true);
        }
      })
      .catch((error) => setSuccessMessage(error.message));
  };

  /* ---- */

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead sx={{ bgcolor: "#102800" }}>
            <TableRow>
              <TableCell sx={{ color: "#ffffff" }}>Id</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Reviews</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Rating</TableCell>
              {/* <TableCell sx={{ color: "#ffffff" }}>Action</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {reviewsData.length ? (
              reviewsData.map((val, key) => (
                <TableRow key={key}>
                  <TableCell scope="row">{val.fldi_review_id}</TableCell>
                  <TableCell scope="row">
                    {val.fldi_review_id === selectedId ? (
                      <TextField
                        name="comment"
                        label="Comment"
                        defaultValue={val.fldv_comment}
                        variant="standard"
                        sx={{ width: 1 }}
                        // onChange={handleReviewContentChange}
                      />
                    ) : (
                      val.fldv_comment
                    )}
                  </TableCell>
                  <TableCell>
                    {val.fldi_review_id === selectedId ? (
                      <TextField
                        name="fldi_rating"
                        label="Rating"
                        defaultValue={val.fldi_rating}
                        variant="standard"
                        sx={{ width: 1 }}
                        // onChange={handleReviewContentChange}
                      />
                    ) : (
                      val.fldi_rating
                    )}
                  </TableCell>
                  {/* <TableCell>
                    {isEdit && val.fldi_review_id === selectedId ? (
                      <Button
                        variant="outlined"
                        startIcon={<SaveIcon />}
                        onClick={() => {
                          handleContentSave(val.fldi_review_id);
                        }}
                      >
                        Save
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        startIcon={<EditIcon />}
                        onClick={() => {
                          handleEdit(val.fldi_review_id);
                        }}
                      >
                        Edit
                      </Button>
                    )}
                  </TableCell> */}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5}>No Data Found</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Container maxWidth="100%">
        <Typography
          sx={{ color: isError ? red[600] : green[600], marginTop: "15px" }}
        >
          {successMessage}
        </Typography>
        <Box
          sx={{ marginTop: "20px", marginBottom: "20px", textAlign: "right" }}
        >
          {isNewActive ? (
            <Button
              variant="outlined"
              startIcon={<CloseIcon />}
              onClick={() => {
                handleAddNewClose();
              }}
            >
              Close
            </Button>
          ) : (
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => {
                handleAddNew();
              }}
            >
              Add New
            </Button>
          )}
        </Box>
      </Container>

      {/* new add table */}

      {isNewActive && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead sx={{ bgcolor: "#102800" }}>
              <TableRow>
                <TableCell sx={{ color: "#ffffff" }}>Review</TableCell>
                <TableCell sx={{ color: "#ffffff" }}>Rating</TableCell>
                <TableCell sx={{ color: "#ffffff" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell scope="row">
                  <TextField
                    name="comment"
                    label="Comment"
                    variant="standard"
                    sx={{ width: 1 }}
                    onChange={handleNewReviewContentChange}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    name="rating"
                    label="Rating"
                    variant="standard"
                    sx={{ width: 1 }}
                    onChange={handleNewReviewContentChange}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    startIcon={<SaveIcon />}
                    onClick={() => {
                      handleAddContentSave();
                    }}
                  >
                    Save
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default Reviews;
