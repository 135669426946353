import React from "react";
import "../index.scss";

export const ContactWrapCont = ({ title, link, textlink }) => {
    return (
        <>
            <span className="contact-title">{title}</span>
            <span className="contact-text">
                <a href={link}>
                    {textlink}
                </a>
            </span>
        </>
    );
};
