import React from "react";
import { motion } from "framer-motion";

const PageTransition = () => {
  const blackBox = {
    animate: {
      height: "0",
      transition: {
        duration: 1.5,
        ease: [0.87, 0, 0.13, 1],
      },
    },
    initial: {
      height: "100vh",
    },
  };
  return (
    <motion.div
      className="overlayPageTransition"
      initial="initial"
      animate="animate"
      variants={blackBox}
    />
  );
};

export default PageTransition;
