import React from "react";
import { FaStar } from "@react-icons/all-files/fa/FaStar";
// import { FaStarHalfAlt } from "@react-icons/all-files/fa/FaStarHalfAlt";
import QuoteIcon from "../assets/quote-icon.png";
import "../index.scss";

const CustomerQuoteWrap = ({ quoteText }) => {
  return (
    <div className="reviews-wrapper">
      <div className="quote-wrapper">
        <img src={QuoteIcon} alt="" className="img-fluid" />
      </div>
      <div className="reviews-box">
        <div className="star-section text-center">
          <FaStar className="icon" />
          <FaStar className="icon" />
          <FaStar className="icon" />
          <FaStar className="icon" />
          <FaStar className="icon" />
        </div>
      </div>
      <p>{quoteText}</p>
      <div className="reviews-box-bot">
        <div className="star-section"></div>
      </div>
    </div>
  );
};

export default CustomerQuoteWrap;
