import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

/* imports */
import Sidebar from "../components/Sidebar";

const Dashboard = ({ pageTitle, setPageTitle, isLogin, setIsLogin }) => {
  const navigate = useNavigate();

  const redirectionAfterLogin = () => {
    if (!isLogin) {
      navigate("/admin/login");
    }
  };
  useEffect(() => {
    redirectionAfterLogin();
  }, []);

  return (
    <>
      <Sidebar
        pageTitle={pageTitle}
        setPageTitle={setPageTitle}
        setIsLogin={setIsLogin}
      />
    </>
  );
};

export default Dashboard;
