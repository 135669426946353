import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Button,
  TextField,
} from "@mui/material";
import axios from "axios";
import { green } from "@mui/material/colors";
import { baseUrl, endPoints } from "../../data/endpoints";

const About = () => {
  const POSTAPI = `${baseUrl}${endPoints.postAboutData}`;
  const API = `${baseUrl}${endPoints.getAboutData}`;

  const aboutDataObj = {
    slug: "about",
    image_one: "",
    image_two: "",
    desc_one: "",
    desc_two: "",
  };

  const [aboutParaOne, setAboutParaOne] = useState("");
  const [aboutParaTwo, setAboutParaTwo] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isAboutEdit, setIsAboutEdit] = useState(false);
  let [aboutData, setAboutData] = useState(aboutDataObj);

  /* start getting data */

  const getAboutData = (url) => {
    axios
      .get(url)
      .then((res) => {
        let aboutData = res.data.data[0];
        if (aboutData) {
          setAboutParaOne(aboutData.fldv_desc_one);
          setAboutParaTwo(aboutData.fldv_desc_two);
        }
      })
      .catch((error) => console.log(error.message));
  };

  useEffect(() => {
    getAboutData(API);
  }, [API]);

  /* end getting data */

  // An-Nur is a Unani product manufacturing company that is ISO 9001:2015, HACCP certified. All An-Nur products are FDA and GMP certified, with a reputation of being of the utmost high quality following the most stringent quality parameters for eye care products. Our products involve the use of genuine and effective ancient herbs. This marked the beginning of An-Nur while leaving its founders with a vision to provide pure herbal remedies for generations to come.
  // An-Nur Surmas and Kajals are made in accordance with the authentic formulae established by stalwarts of Unani medicine. The efficacy of ancient herbs and formulae is enhanced with modern research and methods of production. With constant innovation and determination, An-Nur has produced various types of Surmas and Kajals, each dedicated to cater various eye care concerns and provide the comfort of healthy vision.

  /* ------------- */

  const handleIsEdit = () => {
    setIsAboutEdit(true);
  };
  /* ------------- */

  const handleAboutContentChange = (e) => {
    setAboutData({ ...aboutData, [e.target.name]: e.target.value });
  };

  const handleAboutContentSave = () => {
    setIsAboutEdit(false);

    aboutData.desc_one === "" && (aboutData.desc_one = aboutParaOne);

    aboutData.desc_two === "" && (aboutData.desc_two = aboutParaTwo);

    axios
      .post(POSTAPI, aboutData)
      .then((response) => {
        setSuccessMessage(response.data.message);
        getAboutData(API);
        setAboutData(aboutDataObj);
      })
      .catch((error) => setSuccessMessage(error.message));
  };

  return (
    <>
      <Container maxWidth="100%">
        <Typography sx={{ color: green[600] }}>{successMessage}</Typography>
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Typography>{aboutParaOne}</Typography>
            <Typography>{aboutParaTwo}</Typography>
          </CardContent>
        </Card>

        {isAboutEdit && (
          <>
            <Typography variant="h5" sx={{ mt: "10px" }}>
              Edit Content Below
            </Typography>
            <Card>
              <CardContent>
                <div>
                  <TextField
                    name="desc_one"
                    label="Paragraph 1"
                    multiline
                    rows={5}
                    defaultValue={aboutParaOne}
                    variant="standard"
                    sx={{ width: 1 }}
                    onChange={handleAboutContentChange}
                  />
                  <TextField
                    name="desc_two"
                    label="Paragraph 2"
                    multiline
                    rows={5}
                    defaultValue={aboutParaTwo}
                    variant="standard"
                    sx={{ width: 1, mt: "20px" }}
                    onChange={handleAboutContentChange}
                  />
                </div>
              </CardContent>
            </Card>
            <Button
              variant="contained"
              sx={{ marginTop: "15px" }}
              onClick={() => {
                handleAboutContentSave();
              }}
            >
              Save
            </Button>
          </>
        )}

        {!isAboutEdit && (
          <Button
            variant="contained"
            sx={{ marginTop: "15px" }}
            onClick={() => {
              handleIsEdit();
            }}
          >
            Edit
          </Button>
        )}
      </Container>
    </>
  );
};

export default About;
