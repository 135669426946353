import React, { useEffect, useState } from "react";
import axios from "axios";
import "../index.scss";
import SectionHeading from "./SectionHeading";
import ProductListSlider from "./ProductListSlider";
import { baseUrl, endPoints } from "../data/endpoints";

const KajalRange = () => {
  const API = `${baseUrl}${endPoints.getProductsData}`;

  const [productList, setProductList] = useState([]);

  /* start getting data */

  const getProductData = (url) => {
    axios
      .get(url)
      .then((res) => {
        let productData = res.data.data;
        if (productData) {
          setProductList(
            productData.filter((val) => {
              return val.fldi_cat_id === "2";
            })
          );
        }
      })
      .catch((error) => console.log(error.message));
  };

  useEffect(() => {
    getProductData(API);
  }, [API]);

  /* end getting data */

  return (
    <section className={`product-range section-primary`}>
      <div className="container h-100">
        <div className="row g-0 align-items-center h-100">
          <div className="col-12 text-center">
            <SectionHeading
              textColor="textColorSecondary"
              sectiontitle="Kajal"
              sectiontitle2="Range"
            />
          </div>
          <div className="col-12 pt-5">
            <ProductListSlider
              productList={productList && productList}
              category="kajal-collection"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default KajalRange;
