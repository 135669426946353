import React, { useEffect, useState, useRef } from "react";
// import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Logo from "../assets/logo.png";
import Burger from "../assets/burger-icon.png";
import "../index.scss";
const Header = ({ isProductDetails, navbar }) => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const onClick = () => setIsActive(!isActive);

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  const removeDropBox = () => {
    setIsActive(!isActive);
  };

  return (
    <div className="header-section">
      <div
        className={
          !isProductDetails
            ? scroll
              ? "inner-wrapper inner-wrapper-bg"
              : "inner-wrapper"
            : "inner-wrapper inner-wrapper-bg"
        }
      >
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center">
            <div className="logo-section">
              <HashLink smooth to="/#top" className="react-link">
                <img src={Logo} alt="Annur Logo" className="logo" />
              </HashLink>
            </div>
            <div className="burger-section" onClick={onClick}>
              <img src={Burger} alt="Burger Icon" className="burger-icon" />
            </div>
          </div>
        </div>

        <div
          ref={dropdownRef}
          className={`dropdown-menu-wrapper ${
            isActive ? "active" : "inactive"
          }`}
        >
          <ul>
            <li>
              <HashLink
                smooth
                to="/#about-section"
                className="menu-link"
                onClick={() => removeDropBox()}
              >
                ABOUT US
              </HashLink>
            </li>
            <li>
              <HashLink
                smooth
                to="/#collections"
                className="menu-link"
                onClick={() => removeDropBox()}
              >
                COLLECTIONS
              </HashLink>
            </li>
            <li>
              <HashLink
                smooth
                to="/#certifications"
                className="menu-link"
                onClick={() => removeDropBox()}
              >
                CERTIFICATIONS
              </HashLink>
            </li>
            <li>
              <HashLink
                smooth
                to="/#foot-contact"
                className="menu-link"
                onClick={() => removeDropBox()}
              >
                CONTACT US
              </HashLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
