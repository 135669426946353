//kajal imports

import kajalProductClose from "../assets/kajal/kajalProductClose.png";
import kajalProductOpen from "../assets/kajal/kajalProductOpen.png";
import sunnatDeluxeColdKajal1 from "../assets/kajal/sunnatDeluxeColdKajal1.png";
import sunnatDeluxeColdKajal2 from "../assets/kajal/sunnatDeluxeColdKajal2.png";
import sunnatDeluxeColdKajal3 from "../assets/kajal/sunnatDeluxeColdKajal3.png";
import sunnatDeluxeColdKajal4 from "../assets/kajal/sunnatDeluxeColdKajal4.png";
import sunnatKajal1 from "../assets/kajal/sunnatKajal1.png";
import sunnatKajal2 from "../assets/kajal/sunnatKajal2.png";
import sunnatKajal3 from "../assets/kajal/sunnatKajal3.png";
import sunnatKajal4 from "../assets/kajal/sunnatKajal4.png";
import zeyaDeluxeColdKajal1 from "../assets/kajal/zeyaDeluxeColdKajal1.png";
import zeyaDeluxeColdKajal2 from "../assets/kajal/zeyaDeluxeColdKajal2.png";
import zeyaDeluxeColdKajal3 from "../assets/kajal/zeyaDeluxeColdKajal3.png";
import zeyaDeluxeColdKajal4 from "../assets/kajal/zeyaDeluxeColdKajal4.png";
import zeyaKajal1 from "../assets/kajal/zeyaKajal1.png";
import zeyaKajal2 from "../assets/kajal/zeyaKajal2.png";
import zeyaKajal3 from "../assets/kajal/zeyaKajal3.png";
import zeyaKajal4 from "../assets/kajal/zeyaKajal4.png";

//surma imports

import aafreenSurma1 from "../assets/surma/aafreenSurma1.png";
import aafreenSurma2 from "../assets/surma/aafreenSurma2.png";
import deepBlackSurma1 from "../assets/surma/deepBlackSurma1.png";
import deepBlackSurma2 from "../assets/surma/deepBlackSurma2.png";
import gulabSadaSurma1 from "../assets/surma/gulabSadaSurma1.png";
import gulabSadaSurma2 from "../assets/surma/gulabSadaSurma2.png";
import jannatSurma1 from "../assets/surma/jannatSurma1.png";
import jannatSurma2 from "../assets/surma/jannatSurma2.png";
import sanaSurma1 from "../assets/surma/sanaSurma1.png";
import sanaSurma2 from "../assets/surma/sanaSurma2.png";
import sunnatDeluxeSurma1 from "../assets/surma/sunnatDeluxeSurma1.png";
import sunnatDeluxeSurma2 from "../assets/surma/sunnatDeluxeSurma2.png";
import sunnatDeluxeSurma3 from "../assets/surma/sunnatDeluxeSurma3.png";
import sunnatMamiraSurma1 from "../assets/surma/sunnatMamiraSurma1.png";
import sunnatMamiraSurma2 from "../assets/surma/sunnatMamiraSurma2.png";
import sunnatMotiSurma1 from "../assets/surma/sunnatMotiSurma1.png";
import sunnatMotiSurma2 from "../assets/surma/sunnatMotiSurma2.png";
import sunnatNeemSurma1 from "../assets/surma/sunnatNeemSurma1.png";
import sunnatNeemSurma2 from "../assets/surma/sunnatNeemSurma2.png";
import sunnatSurma1 from "../assets/surma/sunnatSurma1.png";
import sunnatSurma2 from "../assets/surma/sunnatSurma2.png";

//certificate imports

import iconCertFda from "../assets/icons/iconCertFda.png"
import iconCertGmp from "../assets/icons/iconCertGmp.png"
import iconCertHaccp from "../assets/icons/iconCertHaccp.png"
import IconCertIso from "../assets/icons/IconCertIso.png"
import iconHerbal from "../assets/icons/iconHerbal.png"
import iconLeadFree from "../assets/icons/iconLeadFree.png"
import iconMamira from "../assets/icons/iconMamira.png"
import iconNeem from "../assets/icons/iconNeem.png"
import iconPearl from "../assets/icons/iconPearl.png"
import iconPipal from "../assets/icons/iconPipal.png"
import iconSmudgeFree from "../assets/icons/iconSmudgeFree.png"
import iconRose from "../assets/icons/iconRose.png"
import iconWhitePepper from "../assets/icons/iconWhitePepper.png"
import iconCowsGhee from "../assets/icons/iconCowsGhee.png"



export const kajalImages = {
    kajalProductClose,
    kajalProductOpen,
    sunnatDeluxeColdKajal1,
    sunnatDeluxeColdKajal2,
    sunnatDeluxeColdKajal3,
    sunnatDeluxeColdKajal4,
    sunnatKajal1,
    sunnatKajal2,
    sunnatKajal3,
    sunnatKajal4,
    zeyaDeluxeColdKajal1,
    zeyaDeluxeColdKajal2,
    zeyaDeluxeColdKajal3,
    zeyaDeluxeColdKajal4,
    zeyaKajal1,
    zeyaKajal2,
    zeyaKajal3,
    zeyaKajal4,
}

export const surmaImages = {
    aafreenSurma1,
    aafreenSurma2,
    deepBlackSurma1,
    deepBlackSurma2,
    gulabSadaSurma1,
    gulabSadaSurma2,
    jannatSurma1,
    jannatSurma2,
    sanaSurma1,
    sanaSurma2,
    sunnatDeluxeSurma1,
    sunnatDeluxeSurma2,
    sunnatDeluxeSurma3,
    sunnatMamiraSurma1,
    sunnatMamiraSurma2,
    sunnatMotiSurma1,
    sunnatMotiSurma2,
    sunnatNeemSurma1,
    sunnatNeemSurma2,
    sunnatSurma1,
    sunnatSurma2,
}

export const productIcons = {
    iconCertFda,
    iconCertGmp,
    iconCertHaccp,
    IconCertIso,
    iconHerbal,
    iconLeadFree,
    iconMamira,
    iconNeem,
    iconPearl,
    iconPipal,
    iconSmudgeFree,
    iconRose,
    iconWhitePepper,
    iconCowsGhee
}