import React from "react";
import { Link } from "react-router-dom";

const DetailsNav = ({ category, productNextSlug, productPrevSlug }) => {
  return (
    <div className="detailNavArrows">
      {productPrevSlug ? (
        <Link to={`/${category}/${productPrevSlug}`} className="react-link">
          <span className="arrows">
            <span className="prevArrow"></span>
          </span>
        </Link>
      ) : (
        <span className="arrows deactivated">
          <span className="prevArrow"></span>
        </span>
      )}

      {productNextSlug ? (
        <Link to={`/${category}/${productNextSlug}`} className="react-link">
          <span className="arrows">
            <span className="nextArrow"></span>
          </span>
        </Link>
      ) : (
        <span className="arrows deactivated">
          <span className="nextArrow"></span>
        </span>
      )}
    </div>
  );
};

export default DetailsNav;
